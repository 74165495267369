import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";

// Styles //
import styles from "../styles/modules/containers/UniswapSimulator.module.css";
import themeProps from "../data/themeProperties.json";

// Layout //
import NavBar from "../layout/NavBar";
import SideBar from "../layout/SideBar";
import DashBoard from "../layout/DashBoard";
import PoolOverview from "../layout/PoolOverview";
import StrategyOverview from "../layout/StrategyOverview";
import PoolPriceLiquidity from "../layout/PoolPriceLiquidity";
import StrategyBacktest from "../layout/StrategyBacktest";

// Components //
import Grid from "../components/Grid";
import UniswapLink from "../components/uniswap/UniswapLink";

// Data //
import { protocols, setProtocol } from "../store/protocol";
import { poolById } from "../api/thegraph/uniPools";
import { fetchPoolData, selectPool, selectBaseTokenId } from "../store/pool";
import { setWindowDimensions, selectWindowDimensions } from "../store/window";
import { selectProtocolId } from "../store/protocol";
import { setStrategyColors } from "../store/strategies";
import colors from "../data/colors.json";
import { setTokenRatioColors } from "../store/tokenRatios";
import {
  setStrategyRangeColors,
  selectStrategyRangeMinValues,
  selectStrategyRangeMaxValues,
  selectSelectedEditableStrategyRanges,
  selectStrategyRanges,
  selectEditableStrategyRanges,
  setTokenRatio,
} from "../store/strategyRanges";

// New stuff
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  selectBaseToken,
  selectCurrentPrice,
  selectQuoteToken,
  selectFeeTier,
  selectPoolDayData,
  selectYesterdaysPriceData,
  selectNormStd,
  selectLiquidity,
  selectPriceBase,
  selectPriceToken,
} from "../store/pool";
import { filterTicks, calc24HrFee, calcCLI } from "../helpers/uniswap/liquidity";
import { round } from "../helpers/numbers";
import { genChartData as genStrategyChartData, genV3StrategyData } from "../helpers/uniswap/strategiesChartData";
import { maxInArray, minInArray } from "../helpers/numbers";
import { selectStrategies, selectStrategiesByIds } from "../store/strategies";
import { selectInvestment } from "../store/investment";

const Data = (props) => {
  //-----------------------------------------------------------------------------------------------
  // WINDOW DIMENSION STATE
  //-----------------------------------------------------------------------------------------------

  const pageMinWidth = 1200;
  const windowDim = useSelector(selectWindowDimensions);
  const dispatch = useDispatch();

  const handleResize = () => {
    const docEl = document.documentElement;
    docEl.style.setProperty("--window-height", parseInt(window.innerHeight) + "px");
    if (window.innerWidth >= pageMinWidth) {
      dispatch(setWindowDimensions({ width: window.innerWidth, height: window.innerHeight }));
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  //-----------------------------------------------------------------------------------------------
  // Set CHART COLORS ON LOAD //
  //-----------------------------------------------------------------------------------------------

  useEffect(() => {
    dispatch(setStrategyColors("uniswap"));
    dispatch(setStrategyRangeColors("uniswap"));
    dispatch(setTokenRatioColors("uniswap"));
  });

  //-----------------------------------------------------------------------------------------------
  // GET DEFAULT POOL ON LOAD (USDC / WETH) 0.3%
  //-----------------------------------------------------------------------------------------------
  const protocolFromStore = useSelector(selectProtocolId);
  const poolS = useSelector(selectPool);

  const { search: querySearch } = useLocation();
  const queryParams = queryString.parse(querySearch);
  const queryPoolId = queryParams.poolId;
  const queryChainId = queryParams.chainId;
  const queryRangeMin = queryParams.rangeMin;
  const queryRangeMax = queryParams.rangeMax;
  const queryCurrentPrice = queryParams.currentPrice;
  const [protocol, setProtocolState] = useState(queryChainId?.length > 0 ? protocols.find((prot) => prot.id === Number(queryChainId)) : protocolFromStore);
  const history = useNavigate();

  useEffect(() => {
    const abortController = new AbortController();

    if (queryChainId && queryChainId.length > 0) {
      const givenProtocol = protocols.find((prot) => prot.id === Number(queryChainId));

      if (givenProtocol) {
        dispatch(setProtocol(protocol));
      }
    }

    async function getPoolId() {
      await poolById(queryPoolId?.length > 10 ? queryPoolId : "0x8ad599c3a0ff1de082011efddc58f1908eb6e6d8", abortController.signal, protocol.id).then(
        (pool) => {
          if (pool) {
            dispatch(fetchPoolData(pool, { rangeMin: queryRangeMin, rangeMax: queryRangeMax, currentPrice: queryCurrentPrice }));
          }
        }
      );
    }

    getPoolId();
  }, []);

  const stringified = queryString.stringify(
    {
      poolId: queryPoolId?.length > 10 ? queryPoolId : "0x8ad599c3a0ff1de082011efddc58f1908eb6e6d8",
      chainId: queryChainId ? queryChainId : 1,
      rangeMin: queryRangeMin ? queryRangeMin : undefined,
      rangeMax: queryRangeMax ? queryRangeMax : undefined,
      currentPrice: queryCurrentPrice ? queryCurrentPrice : undefined,
    },
    { arrayFormat: "comma" }
  );

  // useEffects for filter query strings
  useEffect(() => {
    history({ search: stringified });
  }, [history, stringified]);

  //-----------------------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------------------

  // 1. PoolOverview
  const poolVolumeData = useSelector(selectPoolDayData);

  // TVL
  const [dailyTvlData, setDailyTvlData] = useState();
  const [dailyTvlDomain, setDailyTvlDomain] = useState();

  // Volume
  const [dailyVolumeData, setDailyVolumeData] = useState();
  const [dailyVolumeDomain, setDailyVolumeDomain] = useState();

  const genChartData = (data, yKey) => {
    return data.map((d) => {
      const date = new Date(0);
      return { x: date.setUTCSeconds(d.date), y: parseFloat(d[yKey]) || 0 };
    });
  };

  const genDomain = (data) => {
    const maxY = Math.max(...data.map((d) => d.y));
    const datesForX = data.map((d) => d.x).reverse();
    return { x: datesForX, y: [0, maxY] };
  };

  useEffect(() => {
    if (poolVolumeData) {
      const tvlData = genChartData(poolVolumeData, "tvlUSD");
      setDailyTvlData(tvlData);
      setDailyTvlDomain(genDomain(tvlData));

      const volumeData = genChartData(poolVolumeData, "volumeUSD");
      setDailyVolumeData(volumeData);
      setDailyVolumeDomain(genDomain(volumeData));
    }
  }, [poolVolumeData]);

  // Stats
  const yesterday = useSelector(selectYesterdaysPriceData);
  const pool = useSelector(selectPool);
  const normStd = useSelector(selectNormStd);
  const liquidity = useSelector(selectLiquidity);
  const baseToken = useSelector(selectBaseToken);
  const quoteToken = useSelector(selectQuoteToken);
  const basePrice = baseToken.currentPrice;
  const [fee24Hr, setFee24Hr] = useState("");
  const [CLI, setCLI] = useState("");

  useEffect(() => {
    if (pool && pool.token0) {
      setFee24Hr(calc24HrFee(yesterday, pool));
      setCLI(calcCLI(liquidity, normStd, pool, basePrice));
    }
  }, [yesterday, pool, normStd, liquidity, basePrice]);

  // 2. Strategy Overview
  const currentPrice = useSelector(selectCurrentPrice);
  const investment = useSelector(selectInvestment);
  const strategiesAll = useSelector(selectStrategies);
  const strategies = props.strategies ? selectStrategiesByIds(strategiesAll, props.strategies) : strategiesAll;
  const strategyRanges = useSelector(selectStrategyRanges);
  const [strategyOverviewChartData, setStrategyOverviewChartData] = useState();
  const [strategyOverviewChartDomain, setStrategyOverviewChartDomain] = useState();
  const [v3StrategyData, setV3StrategyData] = useState();

  // Generate new Asset Value chart data when input values change
  useEffect(() => {
    const newChartData = genStrategyChartData(currentPrice, investment, strategyRanges, strategies, props.chartDataOverride);
    setStrategyOverviewChartData(newChartData);
  }, [currentPrice, investment, props.chartDataOverride, strategies, strategyRanges]);

  // Generate V3 Strategy data used for drag controls when chart data or Strategy Range values change
  useEffect(() => {
    if (strategyOverviewChartData) {
      setV3StrategyData(genV3StrategyData(currentPrice, investment, strategyRanges, strategies, strategyOverviewChartData, "data"));
    }
  }, [strategyOverviewChartData, currentPrice, investment, strategies, strategyRanges]);

  // Generate Asset Value Chart's domain when chart data changes
  useEffect(() => {
    if (strategyOverviewChartData && strategyOverviewChartData.length) {
      const xMax = maxInArray(
        strategyOverviewChartData.map((d) => d["data"]),
        "x"
      );
      const yMax = maxInArray(
        strategyOverviewChartData.map((d) => d["data"]),
        "y"
      );
      const yMin = minInArray(
        strategyOverviewChartData.map((d) => d["data"]),
        "y"
      );
      const xMin = minInArray(
        strategyOverviewChartData.map((d) => d["data"]),
        "x"
      );

      setStrategyOverviewChartDomain({ x: [Math.min(xMin, 0), Math.max(xMax, 0)], y: [Math.min(yMin, 0), Math.max(yMax, Math.abs(yMin * 0.2))] });
    }
  }, [strategyOverviewChartData]);

  // 3. DailyPriceChart
  const dailyPrices = useSelector(selectPoolDayData);
  const dailyStrategyRanges = useSelector(selectSelectedEditableStrategyRanges);
  const priceBase = useSelector(selectPriceBase);
  const priceToken = useSelector(selectPriceToken);

  const [dailyCandleDataState, setDailyCandleData] = useState();
  const [dailyChartData, setDailyChartData] = useState();
  const [dailyChartDomain, setDailyChartDomain] = useState();

  const dailyCandleData = (data, baseSymbol, quoteSymbol) => {
    const candleData = {};
    candleData[baseSymbol] = [];
    candleData[quoteSymbol] = [];

    data.forEach((d, i) => {
      const yesterday = data[Math.min(data.length - 1, i + 1)];

      const date = new Date(d.date * 1000);

      candleData[baseSymbol].push({
        date: date,
        close: parseFloat(d.close) || 0,
        open: parseFloat(yesterday.close) || 0,
        min: parseFloat(Math.min(d.close, yesterday.close)) || 0,
        max: parseFloat(Math.max(d.close, yesterday.close)) || 0,
        high: parseFloat(d.high) || 0,
        low: parseFloat(d.low) || 0,
        green: parseFloat(d.close) > parseFloat(yesterday.close) ? 1 : 0,
      });

      candleData[quoteSymbol].push({
        date: date,
        close: parseFloat(d.close) === 0 ? 0 : 1 / parseFloat(d.close) || 0,
        open: parseFloat(yesterday.close) === 0 ? 0 : 1 / parseFloat(yesterday.close) || 0,
        min: parseFloat(d.close) === 0 ? 0 : parseFloat(Math.min(1 / d.close, 1 / yesterday.close)) || 0,
        max: parseFloat(d.close) === 0 ? 0 : parseFloat(Math.max(1 / d.close, 1 / yesterday.close)) || 0,
        high: parseFloat(d.close) === 0 ? 0 : 1 / parseFloat(d.low) || 0,
        low: parseFloat(d.close) === 0 ? 0 : 1 / parseFloat(d.high) || 0,
        green: parseFloat(d.close) > parseFloat(yesterday.close) ? 1 : 0,
      });
    });

    return candleData;
  };

  useEffect(() => {
    if (dailyPrices && pool) {
      setDailyCandleData(dailyCandleData(dailyPrices, priceBase, priceToken));
    }
  }, [dailyPrices, pool, priceBase, priceToken]);

  // 4. LiquidityDensityChart
  const [liquidityChartData, setLiquidityChartData] = useState();
  const [liquidityChartDomain, setLiquidityChartDomain] = useState();
  const [currentPriceLineData, setCurrentPriceLineData] = useState({ x1: 0, x2: 0 });
  const baseTokenId = useSelector(selectBaseTokenId);
  const strategyMinRanges = useSelector(selectStrategyRangeMinValues);
  const strategyMaxRanges = useSelector(selectStrategyRangeMaxValues);

  useEffect(() => {
    if (liquidity && liquidity[0]) {
      const min = currentPrice - pool.std * 3;
      const max = currentPrice + pool.std * 3;
      const filterChartData = filterTicks(liquidity, liquidity[0].pool.tick, [min, max], pool, 0.9);
      setLiquidityChartData(filterChartData);
      setCurrentPriceLineData({ x1: liquidity[0].pool.tick, x2: liquidity[0].pool.tick });
    }
  }, [currentPrice, liquidity, pool, props.zoomLevel]);

  useEffect(() => {
    if (liquidityChartData) {
      const domain = genDomain(liquidityChartData, baseTokenId);
      setLiquidityChartDomain(domain);
    }
  }, [baseTokenId, liquidityChartData, pool, strategyMaxRanges, strategyMinRanges]);

  return (
    <pre style={{ backgroundColor: "white" }}>
      {dailyVolumeData === undefined || strategyOverviewChartData === undefined
        ? JSON.stringify({ loading: true }, null, 2)
        : JSON.stringify(
            {
              loading: false,
              // PoolOverview
              tvl: { dailyTvlData, dailyTvlDomain },
              volume: { dailyVolumeData, dailyVolumeDomain },
              poolStats: {
                volatility: round(normStd, 2),
                activeLiquidity: round(fee24Hr, 2),
                cli: round(CLI, 2),
              },
              // StrategyOverview
              strategyOverviewChart: strategyOverviewChartData,
              strategyOverviewDomain: strategyOverviewChartDomain,
              strategyOverviewv3Strategy: v3StrategyData,
              // PoolPriceLiquidity
              dailyPriceChartData: dailyChartData,
              dailyPriceChartDomain: dailyChartDomain,
              // LiquidityDensityChart
              liquidityChartData,
              liquidityChartDomain,
              // StrategyBacktest

              // Othere
              baseToken,
              quoteToken,
            },
            null,
            2
          )}
      {/* <PoolOverview page='uniswap' pageStyle={styles} /> */}
      {/* <StrategyOverview page='uniswap' pageStyle={styles} colors={colors["uniswap"]} /> */}
      {/* <PoolPriceLiquidity page='uniswap' pageStyle={styles} /> */}
      {/* {protocol === 2 ? (
        <div className={styles["arbitrum-error-message"]}>
          {" "}
          Sorry, we're not able to genarate an accurate backtest for Arbitrum currently. We'll be sure to add it once accurate data becomes available.
          <br></br>{" "}
        </div>
      ) : (
        <StrategyBacktest page='uniswap' pageStyle={styles} />
      )} */}
      {/* <StrategyBacktest  page="uniswap" pageStyle={styles}></StrategyBacktest> */}
      {/* <SideBar page='uniswap' width={windowDim.width} minWidth={pageMinWidth} protocols={[0, 1, 2, 3, 5]} leverageHidden={true} pageStyle={styles} /> */}
      {/* <UniswapLink /> */}
      {/* <DashBoard page='uniswap' pageStyle={styles} /> */}
    </pre>
  );
};

export default Data;

export const urlForProtocol = (protocol) => {
  return protocol === 1 ? "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3" :
    protocol === 42161 ? "https://api.thegraph.com/subgraphs/name/ianlapham/arbitrum-minimal" :
    protocol === 137 ? "https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-polygon" :
    protocol === 10 ? "https://api.thegraph.com/subgraphs/name/ianlapham/optimism-post-regenesis" :
    protocol === 42220 ? "https://api.thegraph.com/subgraphs/name/jesse-sawa/uniswap-celo" :
    "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3";
}

export const minTvl = (protocol) => {
  return protocol === 0 ? 10000 : 1;
}

export const requestBody = (request) => {
  
  if(!request.query) return;

  const body = {
      method:'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        query: request.query,
        variables: request.variables || {}
      })
  }

  if (request.signal) body.signal = request.signal;
  return body;

}



import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";

// Styles //
import styles from "../styles/modules/containers/UniswapSimulator.module.css";
import themeProps from "../data/themeProperties.json";

// Layout //
import NavBar from "../layout/NavBar";
import SideBar from "../layout/SideBar";
import DashBoard from "../layout/DashBoard";
import PoolOverview from "../layout/PoolOverview";
import StrategyOverview from "../layout/StrategyOverview";
import PoolPriceLiquidity from "../layout/PoolPriceLiquidity";
import StrategyBacktest from "../layout/StrategyBacktest";

// Components //
import Grid from "../components/Grid";
import UniswapLink from "../components/uniswap/UniswapLink";

// Data //
import { protocols, setProtocol } from "../store/protocol";
import { poolById } from "../api/thegraph/uniPools";
import { fetchPoolData, setLoading, selectPool } from "../store/pool";
import { setWindowDimensions, selectWindowDimensions } from "../store/window";
import { selectProtocolId } from "../store/protocol";
import { setStrategyColors } from "../store/strategies";
import colors from "../data/colors.json";
import { setTokenRatioColors } from "../store/tokenRatios";
import { setStrategyRangeColors } from "../store/strategyRanges";

// New stuff
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";

const UniswapSimulator = (props) => {
  //-----------------------------------------------------------------------------------------------
  // WINDOW DIMENSION STATE
  //-----------------------------------------------------------------------------------------------

  const pageMinWidth = 1200;
  const windowDim = useSelector(selectWindowDimensions);
  const dispatch = useDispatch();

  const handleResize = () => {
    const docEl = document.documentElement;
    docEl.style.setProperty("--window-height", parseInt(window.innerHeight) + "px");
    if (window.innerWidth >= pageMinWidth) {
      dispatch(setWindowDimensions({ width: window.innerWidth, height: window.innerHeight }));
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  //-----------------------------------------------------------------------------------------------
  // Set CHART COLORS ON LOAD //
  //-----------------------------------------------------------------------------------------------

  useEffect(() => {
    dispatch(setStrategyColors("uniswap"));
    dispatch(setStrategyRangeColors("uniswap"));
    dispatch(setTokenRatioColors("uniswap"));
  });

  //-----------------------------------------------------------------------------------------------
  // GET DEFAULT POOL ON LOAD (USDC / WETH) 0.3%
  //-----------------------------------------------------------------------------------------------\
  const protocolFromStore = useSelector(selectProtocolId);
  // const protocol = useSelector(selectProtocolId);
  const poolS = useSelector(selectPool);

  const { search: querySearch } = useLocation();
  const queryParams = queryString.parse(querySearch);
  const queryPoolId = queryParams.poolId;
  const queryChainId = queryParams.chainId;
  const queryRangeMin = queryParams.rangeMin;
  const queryRangeMax = queryParams.rangeMax;
  const queryCurrentPrice = queryParams.currentPrice;
  const [protocol, setProtocolState] = useState(queryChainId?.length > 0 ? protocols.find((prot) => prot.id === Number(queryChainId)) : protocolFromStore);
  const history = useNavigate();

  useEffect(() => {
    const abortController = new AbortController();

    if (queryChainId && queryChainId.length > 0) {
      const givenProtocol = protocols.find((prot) => prot.id === Number(queryChainId));

      if (givenProtocol) {
        dispatch(setProtocol(protocol));
      }
    }

    async function getPoolId() {
      await poolById(queryPoolId?.length > 10 ? queryPoolId : "0x8ad599c3a0ff1de082011efddc58f1908eb6e6d8", abortController.signal, protocol.id).then(
        (pool) => {
          if (pool) {
            dispatch(fetchPoolData(pool, { rangeMin: queryRangeMin, rangeMax: queryRangeMax, currentPrice: queryCurrentPrice }));
          }
        }
      );
    }

    getPoolId();
  }, []);

  const stringified = queryString.stringify(
    {
      poolId: queryPoolId?.length > 10 ? queryPoolId : "0x8ad599c3a0ff1de082011efddc58f1908eb6e6d8",
      chainId: queryChainId ? queryChainId : 1,
      rangeMin: queryRangeMin ? queryRangeMin : undefined,
      rangeMax: queryRangeMax ? queryRangeMax : undefined,
      currentPrice: queryCurrentPrice ? queryCurrentPrice : undefined,
    },
    { arrayFormat: "comma" }
  );

  // useEffects for filter query strings
  useEffect(() => {
    history({ search: stringified });
  }, [history, stringified]);

  //-----------------------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------------------

  return (
    <div className={styles["App"]}>
      <div className={styles["parent-container"]}>
        <NavBar
          page='uniswap'
          width={windowDim.width}
          minWidth={pageMinWidth}
          title='Uniswap V3 Strategy Simulator'
          themeProps={themeProps.uniswap}
          pageStyle={styles}
          pageid='UniswapV3'
        />
        <Grid
          className={styles["dashboard-container"]}
          rows={150}
          columns={62}
          cellAspectRatio={0.82}
          gridGap={5}
          gridWidth={windowDim.width}
          minWidth={pageMinWidth}
        >
          <PoolOverview page='uniswap' pageStyle={styles} />
          <StrategyOverview page='uniswap' pageStyle={styles} colors={colors["uniswap"]} />
          <PoolPriceLiquidity page='uniswap' pageStyle={styles} />
          {protocol === 42161 ? (
            <div className={styles["arbitrum-error-message"]}>
              {" "}
              Sorry, we're not able to genarate an accurate backtest for Arbitrum currently. We'll be sure to add it once accurate data becomes available.
              <br></br>{" "}
            </div>
          ) : (
            <StrategyBacktest page='uniswap' pageStyle={styles} />
          )}
          <SideBar
            page='uniswap'
            width={windowDim.width}
            minWidth={pageMinWidth}
            protocols={[1, 10, 137, 42161, 42220]}
            leverageHidden={true}
            pageStyle={styles}
            handlePoolSelected={(val) => console.log("test - val", val)}
            strategyRangeMin={queryRangeMin}
            strategyRangeMax={queryRangeMax}
          />
          <UniswapLink />
          <DashBoard page='uniswap' pageStyle={styles} />
        </Grid>
      </div>
    </div>
  );
};

export default UniswapSimulator;
